@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.root {
  animation-name: fadeIn;
  animation-duration: 1s;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding: 0 20px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation-name: spin;
  animation-duration: 0.8s;
  animation-timing-function: steps(12);
  animation-iteration-count: infinite;
  display: block;
  height: 50px;
  transform-origin: 50% 50%;
}

.message {
  text-align: center;
  padding-top: 30px;
}
