.root {
  display: flex;
  align-items: center;
}

.globe {
  margin-right: 2px;
}

.languages {
  display: inline;
}

.link {
  padding: 0 6px;
  text-decoration: none;
  color: var(--color-grey-400);
  letter-spacing: -0.025em;
  line-height: 1.5;
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
}

.link {
  border-right: 1px solid var(--color-grey-100);
}

.link:last-child {
  border: none;
}

.active {
  color: var(--color-grey-800);
}
