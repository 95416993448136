.root {
  height: 64px;
  position: relative;
}

.nav {
  transform: translateY(-64px);
  transition: transform 300ms ease;
  border-bottom: 1px solid var(--color-grey-80);
}

.showAll {
  transform: translateY(0);
}

.margin {
  margin-bottom: 57px;
}
