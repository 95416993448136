.root {
  display: inline-block;
  color: var(--color-teal-600);
  text-decoration: none;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.025em;
  padding-right: calc(1em + 0.25ch);
  position: relative;
  cursor: pointer;
}

.root:hover {
  color: var(--color-teal-700);
}

.root:active {
  color: var(--color-teal-800);
}

.root > span {
  display: inline;
}

.arrow {
  bottom: 0.25em;
  height: 1em;
  padding-left: 0.25ch;
  fill: var(--color-teal-600);
  position: absolute;
  transition: transform var(--transition-duration-medium) var(--transition-timing-function-ease-out-cubic);
}

.arrowDown {
  margin-top: -2px;
  transform: rotate(90deg);
}

.root:hover .arrow {
  fill: var(--color-teal-700);
}

.root:active .arrow {
  fill: var(--color-teal-800);
}

.root:hover .arrowRight {
  transform: translateX(0.25ch);
}

.root:hover .arrowDown {
  transform: rotate(90deg) translateX(0.25ch);
}
