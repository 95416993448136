.root {
  position: relative;
}

.header {
  position: sticky;
  top: 0;
  z-index: 2;
}

.contentWithTopPadding {
  position: relative;
  z-index: 1;
}
