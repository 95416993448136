.root {
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  transition: all 300ms ease;
  padding: 20px 16px;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}

.root:hover {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
}

.root:active {
  box-shadow: none;
}

.root a {
  color: #007475;
  text-decoration: none;
}

.root a:hover {
  color: #005b5c;
  text-decoration: underline;
}

.logo,
.logo > * {
  display: block;
  height: 24px;
}

.text {
  display: block;
  margin-top: 10px;
  color: #717275;
  font-size: 16px;
  line-height: 1.6;
}
