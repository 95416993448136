.root {
  height: 64px;
  background: #fff;
  font-family: inherit;
}

.altRoot {
  composes: root;
}

.nav {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  background-color: #fff;
}

.altRoot .nav {
  background: var(--color-green-25);
}

.hamburger {
  background: none;
  border: none;
  height: 100%;
  margin-bottom: 3px;
  margin-right: 16px;
  display: none;
  outline: none;
  cursor: pointer;
}

.logo {
  width: 125px;
}

.logo > * {
  width: 100%;
}

.colContainer {
  display: flex;
  align-items: center;
  height: 100%;
}

.links {
  position: relative;
  transition: opacity 0.3s ease;
  padding: 0;
  display: flex;
  z-index: 1;
}

.links:global(.enter) {
  display: block;
  opacity: 0;
}

.links:global(.enter-active),
.links:global(.enter-done),
.links:global(.exit) {
  display: block;
  opacity: 1;
  pointer-events: auto;
}

.links:global(.exit-active) {
  display: block;
  opacity: 0;
}

.linkItem {
  display: block;
  height: 64px;
  margin-left: 16px;
}

.altRoot .linkItem {
  background-color: var(--color-green-25);
}

.linkItem a {
  display: inline-flex;
  align-items: center;
  height: 100%;
  color: #515151;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.025em;
  text-decoration: none;
  font-weight: 500;
}

.linkItem:hover a {
  color: var(--color-teal-600);
}

.products {
  height: 38px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #515151;
  font-weight: bold;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #f3f3f3;
  padding: 0 10px;
  outline: none;
}

.altRoot .products {
  background: var(--color-green-25);
}

.productButtonText {
  padding: 0 10px;
}

@media (max-width: 640px) {
  .hamburger {
    display: block;
  }

  .links {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    opacity: 0;
    background: var(--color-grey-60);
    margin: 0;
  }

  .linkItem {
    margin: 0;
    border-bottom: 1px solid var(--color-grey-80);
    height: 47px;
  }

  .linkItem a {
    padding-left: 16px;
  }

  .productButtonText {
    visibility: hidden;
    width: 0;
    padding: 5px;
    overflow: hidden;
  }
}

@media (max-width: 561px) {
  .nav {
    padding: 0 16px;
  }

  .links {
    pointer-events: none;
  }
}

@media (max-width: 1024px) {
  .nav {
    box-shadow: none;
    border-bottom: 1px solid #f3f3f3;
  }

  .root {
    height: 64px;
  }
}
