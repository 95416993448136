.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  padding: 0 24px;
  background: var(--color-white);
  font-family: inherit;
  position: relative;
}

.altRoot {
  composes: root;
  background: var(--color-green-25);
}

.title {
  padding-right: 16px;
  border-right: 1px solid var(--color-grey-80);
  font-weight: 500;
  color: #89898c;
  letter-spacing: -0.025em;
  font-size: 20px;
  position: absolute;
}

.links {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 0;
  margin: 0;
  transition: opacity 300ms ease;
  height: 100%;
  pointer-events: auto;
}

.links:global(.enter) {
  display: block;
  opacity: 0;
}

.links:global(.enter-active),
.links:global(.enter-done),
.links:global(.exit) {
  display: block;
  opacity: 1;
  pointer-events: auto;
}

.links:global(.exit-active) {
  display: block;
  opacity: 0;
}

.linkItem {
  display: flex;
  align-items: center;
  list-style: none;
  height: 100%;
}

.altRoot .linkItem {
  background-color: var(--color-green-25);
}

.link {
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--color-grey-400);
  text-decoration: none;
  font-weight: 500;
  border-bottom: 2px solid transparent;
  transition: all 200ms ease;
  font-size: 16px;
  letter-spacing: -0.025em;
}

.active {
  color: var(--color-teal-600);
  border-bottom: 2px solid var(--color-teal-600);
}

.link:hover {
  color: var(--color-teal-600);
}

.linkItem:not(:last-of-type) .link {
  margin-right: 30px;
}

.smallScreenActivePage {
  display: none;
  border: none;
  background: none;
  color: var(--color-grey-600);
  letter-spacing: -0.025em;
  line-height: 1.25;
  font-size: 1rem;
  font-weight: var(--font-weight-semibold);
  height: 100%;
  align-items: center;
  cursor: pointer;
}

.chevron {
  padding-left: 10px;
}

.chevron.open {
  transform: rotate(180deg);
  padding-left: 0;
  padding-right: 10px;
}

.languagePicker {
  position: absolute;
  right: 24px;
}

@media (max-width: 640px) {
  .root {
    justify-content: flex-start;
  }

  .title {
    display: none;
  }

  .links {
    display: block;
    position: absolute;
    top: 57px;
    opacity: 0;
    background: var(--color-white);
    left: 0;
    right: 0;
    margin: 0;
    pointer-events: none;
  }

  .linkItem {
    background: var(--color-white);
    height: 47px;
  }

  .link,
  .link:hover {
    margin: 0 16px;
    width: 100%;
    border: none;
    color: var(--color-grey-600);
  }

  .smallScreenActivePage {
    display: flex;
    padding: 0;
  }

  .languagePicker {
    position: absolute;
    right: 16px;
  }
}
