.siteFooter {
  width: calc(100vw - 64px);
  max-width: 1136px;
  margin: 0 auto;
  padding: 48px 0 50px;
}

.topRowContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 32px;
}

.logo * {
  height: 20px;
  margin-right: 100px;
}

.internalLinks {
  margin: 50px 0 40px 0;
}

.bottomRowContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
}

.socials {
  margin: 32px 0;
}

.copyright {
  margin-top: 16px;
}

.bcorpLogo {
  height: 113px;
  fill: var(--color-grey-300);
}

@media (min-width: 721px) {
  .logo * {
    height: 24px;
  }

  .topRowContainer {
    flex-direction: row;
    margin-bottom: 100px;
  }

  .internalLinks {
    margin: 0;
  }

  .bcorp {
    margin-left: auto;
  }
}

@media (min-width: 1024px) {
  .logo * {
    height: 35px;
  }

  .bottomRowContainer {
    flex-direction: row;
    margin-bottom: 32px;
  }

  .socials {
    margin: 0;
  }

  .copyright {
    margin: 0;
  }
}
