.list {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.listItem:not(:first-child) {
  margin: 0 0 0 16px;
}

.link {
  display: flex;
}

@media (max-width: 730px) {
  .listItem:first-child {
    margin-right: 0;
  }

  .listItem {
    margin: 0 16px 0 0;
  }
}

@media (max-width: 450px) {
  .list {
    display: grid;
    grid-template-columns: repeat(6, 48px);
    grid-row-gap: 16px;
  }

  .listItem:nth-child(7) {
    margin: 0;
  }
}
