/* CSS Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  background-color: #fff;
  color: #3e3f3d;
  line-height: 1.5;
  margin: 0;
}

/* The style of [Remove margins from frequently-used elements] was removed due to the Wordpress upgrade to 6.7.2-php8.1 where Updates to
   JavaScript files related to the block editor. And with this upgrade this style was overwriting all the styles */

/*
Classes that can be added to the body by JS
(e.g. to hide scroll bars when a modal is open)
*/

body.OVERFLOW-HIDDEN {
  overflow: hidden;
}

@media (max-width: 799px) {
  body.OVERFLOW-HIDDEN-ON-SMALL-SCREEN {
    overflow: hidden;
  }
}

/* @font-face declaration for Kalam, cursive font */
@font-face {
  font-family: Kalam;
  font-weight: normal;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Cursive';
  src: url('./fonts/kalam.regular.latin-subset.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url('./fonts/Inter-roman.subset.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url('./fonts/Inter-italic.subset.woff2') format('woff2');
}

@font-face {
  font-family: 'PolySans';
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'neutral';
  src: url('./fonts/PolySans-Neutral.woff2') format('woff2');
}

@font-face {
  font-family: 'PolySans';
  font-weight: 600;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'median';
  src: url('./fonts/PolySans-Median.woff2') format('woff2');
}

@font-face {
  font-family: 'PolySans';
  font-weight: 700;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'bulky';
  src: url('./fonts/PolySans-Bulky.woff2') format('woff2');
}

body {
  font-family: Inter, sans-serif;
}

:root {
  --box-shadow: 1px 1px 16px rgba(24, 25, 30, 0.08);

  --color-blue-700: #024cb3;

  --color-green-400: #80b341;
  --color-green-500: #41a600;
  --color-green-25: #ecf6e5;

  --color-grey-40: #fafafa;
  --color-grey-60: #f3f3f3;
  --color-grey-80: #e7e7e8;
  --color-grey-100: #d0d0d1;
  --color-grey-200: #b8b8ba;
  --color-grey-300: #a1a1a3;
  --color-grey-400: #89898c;
  --color-grey-500: #717275;
  --color-grey-600: #5a5a5e;
  --color-grey-700: #424347;
  --color-grey-800: #2b2b30;
  --color-grey-1000: #131419;

  --color-yellow-20: #fef4cb;

  --color-pink-700: #b50b2d;

  --color-purple-400: #6c60d2;
  --color-purple-500: #523ee8;

  --color-orange-20: #ffdbc8;
  --color-orange-400: #ff8643;

  --color-red-500: #bc343c;

  --color-teal-300: #4cc0c1;
  --color-teal-600: #008c8e;
  --color-teal-700: #007475;
  --color-teal-800: #005b5c;

  --color-white: #fff;
  --color-black: #000;

  --font-family-primary: Inter, sans-serif;
  --font-family-handwriting: Kalam, sans-serif;
  --font-family-table: PolySans, Inter, sans-serif;
  --font-weight-thin: 100;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-black: 900;

  --transition-duration-fast: 150ms;
  --transition-duration-medium: 300ms;

  --transition-timing-function-ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
}
