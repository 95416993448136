.list {
  list-style: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.vertical {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
  grid-column-gap: 30px;
}

.listItem {
  margin-right: 24px;
  max-width: 18ch;
}

.vertical .listItem {
  display: inline-block;
  height: 36px;
  margin-right: 10px;
}

.link {
  text-decoration: none;
  color: var(--color-grey-1000);
  transition: color 0.1s linear;
  font-size: 14px;
  font-weight: var(--font-weight-semibold);
}

.vertical .link {
  font-size: 16px;
}

.link:hover,
.link:focus {
  color: var(--color-teal-600);
}

@media (max-width: 400px) {
  .vertical {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 720px) {
  .vertical {
    display: grid;
  }

  .vertical .listItem {
    min-width: 100px;
  }
}
