.container {
  position: relative;
}

.scrollContainer {
  max-height: 90vh;
  overflow: auto;
}

.root {
  width: 722px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  padding: 28px 24px;
  position: absolute;
  border: 1px solid #f3f3f3;
  border-radius: 2px;
  margin-top: 15px;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 300ms ease;
  pointer-events: none;
  background-color: #fff;
  right: 0;
  z-index: 1; /* Needed to stay on top of fixed aspect ratio thumbnails on ArticleCards. */
}

.root:before,
.root:after {
  content: '';
  display: block;
  position: absolute;
  left: 93%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 12px 10px;
}

.root:after {
  top: -10px;
  border-color: transparent transparent #fff transparent;
}

.root:before {
  top: -12px;
  border-color: transparent transparent #f3f3f3 transparent;
}

.root a {
  color: #008c8e;
  text-decoration: none;
}

.root a:hover {
  color: #008c8e;
  text-decoration: underline;
}

.arrow {
  margin-left: 5px;
}

.appear {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}

.title {
  margin-top: 0;
  margin-bottom: 14px;
  font-size: 24px;
  font-weight: 500;
}

.link {
  display: flex;
  align-items: center;
  white-space: pre;
  margin-bottom: 40px;
  font-weight: 500;
}

.product {
  display: inline-block;
  width: calc(50% - 4px);
  min-height: 90px;
}

.product:not(:last-of-type) {
  padding-bottom: 8px;
}

@media all and (min-width: 769px) {
  .product:nth-of-type(odd) {
    padding-left: 8px;
  }
}

@media all and (max-width: 768px) {
  .root {
    width: 581px;
  }

  .product {
    width: 100%;
    padding: 0;
  }
}

@media all and (max-width: 540px) {
  .root {
    width: calc(100vw - 28px);
  }
}
